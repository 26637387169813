<template>
  <div class="receive-detail">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="AmountInfo" :tab="$t(`金额信息`)"></a-tab-pane>
          <a-tab-pane key="ProductInfo" :tab="$t(`明细`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="operateReceivableOrder"
            :text="$t('审核')"
            :params="{
              action: 'check',
              idList: [id],
            }"
            @success="init"
          />
          <PushBtn
            v-if="['FINISHED', 'PART_WRITTEN_OFF', 'WAIT_WRITTEN_OFF'].includes(formData.status)"
            class="ml10"
            :docType="docType"
            :selectedRows="[formData]"
            @pushAdjust="pushAdjust"
          />
          <FindRelatedDocBtn class="ml10" :id="id" :docType="docType" v-if="isDetail" />
          <OperateBtn
            class="ml10"
            v-if="isDetail && ['DRAFT', 'WAIT_CHECK', 'WAIT_WRITTEN_OFF'].includes(formData.status)"
            api="operateReceivableOrder"
            :text="$t('作废')"
            type="danger"
            :params="{
              action: 'cancel',
              idList: [id],
            }"
            @success="init"
          />
          <VoucherBtn
            class="ml10"
            v-if="
              isDetail &&
              !['DRAFT', 'WAIT_CHECK', 'CANCELLED'].includes(formData.status) &&
              !formData.voucherGenerated
            "
            :docType="docType"
            :selectedRows="[formData]"
            @close="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox
              id="BaseInfo"
              :title="$t('基本信息')"
              :showCopy="isDetail"
              @copyOrder="copyOrder(0)"
            >
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`应收单号`)">
                    <a-input disabled v-model="formData.orderSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据类型`)" required>
                    <CommonSelect
                      :list="receivable_order_type"
                      :code.sync="formData.orderType"
                      :placeholder="$t('单据类型')"
                      :disabled="isDisabled || isAdjust"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`业务日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.bizDate"
                      @choose="chooseBizDate"
                      :disabled="isDisabled"
                      :placehold="$t(`业务日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)">
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      :disabled="true"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款组织`)" required>
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :disabled="isDisabled"
                      :code.sync="formData.businessUnitCode"
                      :name.sync="formData.businessUnitName"
                      :placeholder="$t(`收款组织`)"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算客户`)" required>
                    <CommonQuerySelect
                      api="getCommonList"
                      :disabled="isDisabled"
                      :code.sync="formData.customerCode"
                      :name.sync="formData.customerName"
                      :params="{
                        businessUnitCode: formData.businessUnitCode,
                        tableName: 'customer',
                      }"
                      :placeholder="$t(`结算客户`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`原始销售单号`)">
                    <a-input :disabled="isDisabled" v-model="formData.salesOrderSn" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input v-model="formData.remark" :disabled="isDisabled" />
                  </a-form-model-item>
                </a-col>
                <template v-if="isDetail">
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`状态`)">
                      <a-input disabled v-model="receivableOrderStatusMapping[formData.status]" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`创建时间`)">
                      <a-input disabled v-model="formData.createdAt" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`源单类型`)">
                      <a-input
                        disabled
                        v-model="sourceOrderTypeMapping[formData.sourceOrderType]"
                      />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`源单编码`)">
                      <a-input disabled v-model="formData.sourceOrderCode" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`是否生成凭证`)">
                      <a-input
                        disabled
                        :value="+formData.voucherGenerated ? $t('是') : $t('否')"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                </template>
              </a-row>
            </ModalBox>
            <ModalBox id="AmountInfo" :title="$t('金额信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`本位币`)">
                    <SelectCurrency :disabled="true" :code.sync="formData.functionalCurrency" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算货币`)">
                    <SelectCurrency
                      :disabled="isDisabled"
                      :code.sync="formData.settlementCurrency"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item required :label="$t(`金额`)">
                    <PriceInput
                      disabled
                      :currency="formData.settlementCurrency"
                      :value="formData.amountWithoutTax"
                      :placeholder="$t(`金额`)"
                    />
                  </a-form-model-item>
                </a-col>
                <!-- <a-col :span="6">
                  <a-form-model-item :label="$t(`税率`)" required>
                    <a-input-number
                      style="width: 100%"
                      :disabled="isDisabled"
                      v-model="formData.taxRate"
                      :placeholder="$t(`税率`)"
                      @blur="inputVATRateFn"
                    />
                  </a-form-model-item>
                </a-col> -->
                <a-col :span="6">
                  <a-form-model-item :label="$t(`税额`)">
                    <PriceInput
                      :disabled="true"
                      :currency="formData.settlementCurrency"
                      :value="formData.taxAmount"
                      :placeholder="$t(`税额`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`价税合计`)">
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :disabled="true"
                      :value.sync="formData.receivableAmount"
                      :placeholder="$t(`价税合计`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算方式`)" required>
                    <CommonQuerySelect
                      api="getSettlementMethodList"
                      :placeholder="$t(`结算方式`)"
                      :code.sync="formData.settlementMethod"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
          </a-form-model>

          <ModalBox id="ProductInfo" :title="$t('明细')">
            <a-radio-group
              class="mb8"
              v-model="detailType"
              button-style="solid"
              @change="scrollToBottom"
            >
              <a-radio-button value="goods">{{ $t('物料明细') }}</a-radio-button>
              <a-radio-button value="receiptPlan">{{ $t('收款计划') }}</a-radio-button>
            </a-radio-group>
            <div v-show="detailType === 'goods'">
              <div class="pricing-method flex-ct">
                <a-form-model-item :label="$t(`计价方式`)" required>
                  <a-select
                    :disabled="isDisabled"
                    :placeholder="$t(`请选择计价方式`)"
                    v-model="calculateTypeNew"
                    @change="scrollToBottom"
                    style="width: 300px"
                  >
                    <a-select-option value="BY_TOTAL">
                      {{ $t('通过小计计算单价') }}
                    </a-select-option>
                    <a-select-option value="BY_UNIT">
                      {{ $t('通过单价计算小计') }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <div class="mt20 ml20" v-if="calculateTypeNew === 'BY_UNIT'">
                  <a-popover placement="topLeft">
                    <template slot="content">
                      <div>
                        {{ $t('若开启：含税单价可录入，若关闭：单价可录入') }}
                      </div>
                    </template>
                    <span>{{ $t('录入含税单价') }}</span>
                    <a-icon type="question-circle" class="ml6 mr20" />
                  </a-popover>
                  <a-switch v-model="byUnitTaxPrice" :disabled="isDisabled" />
                </div>
              </div>
              <div v-if="!isDisabled" style="display: flex; justify-content: space-between">
                <div style="position: relative; flex: 1">
                  <SearchSku style="width: 300px" @selected="handleChangeSku" />
                  <a-button type="primary" style="margin-left: 15px" @click="addGoodsToTable">
                    <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
                    {{ $t('添加') }}
                  </a-button>
                </div>
                <div class="flex-ct txt fz16" style="font-weight: bold">
                  <div class="mr30 flex-ct">
                    {{ $t('数量合计') }}: {{ purchaseAllQuantity | toThousands }}
                  </div>
                  <div class="mr30 flex-ct">
                    {{ $t('总金额合计') }}:
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :value="purchaseAllAmount"
                      :showTxt="true"
                    />
                  </div>
                  <div class="flex-ct">
                    {{ $t('总价税合计') }}:
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :value="totalReceivableAmount"
                      :showTxt="true"
                    />
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="margin-top: 10px">
                <a-table
                  :columns="columns"
                  :dataSource="tableData"
                  bordered
                  size="middle"
                  :loading="tableLoading"
                  :pagination="false"
                  :scroll="{ x: 1500 }"
                  :rowKey="(record, index) => index"
                >
                  <span slot="discountAmountTitle">
                    <a-tooltip>
                      <template slot="title">
                        {{ $t('当单价乘以数量与小计不等时展示优惠金额') }}
                      </template>
                      {{ $t(`优惠金额`) }}
                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                  <span slot="no" slot-scope="text, record, index">
                    {{ index + 1 }}
                  </span>
                  <span slot="productName" slot-scope="text, record">
                    <div v-if="record.itemName">{{ $t('名称') }}:{{ record.itemName }}</div>
                    <div v-if="record.gtin">{{ $t('代码') }}:{{ record.gtin }}</div>
                  </span>
                  <template slot="itemStyle" slot-scope="text">
                    {{ text }}
                  </template>
                  <span slot="unit" slot-scope="text, record">
                    <SelectUnit
                      :disabled="isDisabled"
                      :list="record.unitList"
                      :code.sync="record.rawUnit"
                    />
                  </span>
                  <span slot="taxAmount" slot-scope="text, record, index">
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :value.sync="record.taxAmount"
                      :showTxt="!isAdjust"
                      @blur="(value) => handleChange(value, 'taxAmount', index)"
                    />
                  </span>
                  <span slot="remark" slot-scope="text, record">
                    <a-input v-model="record.remark" :disabled="isDisabled" />
                  </span>
                  <span slot="action" slot-scope="text, record, index">
                    <a @click="onDelect(index)">
                      <a-icon type="delete" style="color: red" />
                    </a>
                  </span>
                  <template v-for="col in customRenderList" v-slot:[col]="text, record, index">
                    <div :key="col">
                      <!-- includes 用来判断当前的输入类型 -->
                      <div v-if="!isDisabled">
                        <a-input-number
                          v-if="col === 'taxRate' || col === 'quantity'"
                          :placeholder="$t(`请输入`)"
                          :value="text"
                          :disabled="isAdjust"
                          @blur="(e) => handleChange(e.target.value, col, index)"
                        />
                        <PriceInput
                          :currency="formData.settlementCurrency"
                          v-else-if="col === 'unitPrice' || col === 'unitPriceWithTax'"
                          :placeholder="$t(`请输入`)"
                          :disabled="getUnitPriceDisabled(col)"
                          :value="text"
                          @blur="(val) => handleChange(val, col, index)"
                        />
                        <PriceInput
                          :currency="formData.settlementCurrency"
                          v-else-if="col === 'amountWithoutTax'"
                          :placeholder="$t(`请输入`)"
                          :disabled="calculateTypeNew === 'BY_UNIT'"
                          :value="text"
                          @blur="(val) => handleChange(val, col, index)"
                        />
                        <span v-else>
                          <PriceInput
                            :currency="formData.settlementCurrency"
                            :value="text"
                            :showTxt="true"
                          />
                        </span>
                      </div>
                      <span v-else-if="col === 'taxRate' || col === 'quantity'">
                        {{ Number(text) }}
                      </span>
                      <span v-else>
                        <PriceInput
                          :currency="formData.settlementCurrency"
                          :value="text"
                          :showTxt="true"
                        />
                      </span>
                    </div>
                  </template>
                </a-table>
              </div>
            </div>
            <div v-show="detailType === 'receiptPlan'">
              <div class="table-header flex-ct between pb10">
                <div>
                  <a-popover placement="topLeft">
                    <template slot="content">
                      <div>
                        {{ $t('打开：录入应收比例 (%) ，应收金额根据应收比例 (%) 自动计算；') }}
                      </div>
                      <div>
                        {{ $t('关闭：录入应收金额，应收比例 (%) 根据应收金额自动计算。') }}
                      </div>
                    </template>
                    <span>{{ $t('按比例') }}(%)</span>
                    <a-icon type="question-circle" class="ml6 mr20" />
                  </a-popover>
                  <a-switch v-model="byPercent" :disabled="isDisabled" />
                </div>
                <div class="option-btns pr20" v-if="!isDisabled">
                  <span class="text-link fz16" @click="addReceiptPlan({})">
                    {{ $t('增行') }}
                  </span>
                  <span class="text-link fz16 ml10" @click="deleteReceiptPlan">
                    {{ $t('删行') }}
                  </span>
                </div>
              </div>
              <CommonTable
                :columns="receiptPlanColumns"
                :dataSource="showReceiptPlanList"
                :scroll="{ x: 1500 }"
                :showSelect="!isDisabled"
                :showPagination="false"
                :showSetting="false"
                :selectedRowKeys.sync="selectedRowKeys"
              >
                <template v-slot:isPrepay="{ record }">
                  <a-checkbox v-model="record.isPrepay" :disabled="isDisabled" />
                </template>
                <template v-slot:dueDate="{ record }">
                  <DatePicker
                    :needhms="false"
                    style="background-color: #ffffff; width: 100%"
                    :init-date-time="record.dueDate"
                    @choose="
                      (data) => {
                        chooseDueDate(record, data)
                      }
                    "
                    :disabled="isDisabled"
                  />
                </template>
                <template v-slot:receivableRatio="{ text, record, index }">
                  <a-input-number
                    v-if="byPercent"
                    style="width: 100%"
                    v-model="record.receivableRatio"
                    :disabled="isDisabled"
                    :placeholder="$t('请输入')"
                    @blur="checkLessPercent(record, index)"
                  />
                  <span v-else>{{ Number(text) }}</span>
                </template>
                <template v-slot:receivableAmount="{ text, record, index }">
                  <PriceInput
                    v-if="!byPercent"
                    :value.sync="record.receivableAmount"
                    :disabled="isDisabled"
                    :placeholder="$t('请输入')"
                    @blur="
                      () => {
                        checkLessAmount(record, index)
                      }
                    "
                  />
                  <span v-else>
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :value="text"
                      :showTxt="true"
                    />
                  </span>
                </template>
              </CommonTable>
            </div>
          </ModalBox>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import SelectCurrency from '@component/selectCurrency'
import SelectUnit from '@component/selectUnit'
import SearchSku from '@component/searchSku'
import deepClone from '@/common/deepClone'
import OperationLog from '@component/operationLog'
import PushBtn from '@component/pushBtn'
import { add, divide, multiply, subtract } from '@/common/number'
import { toFixedUp, convertKeysToCamelCase, getUnitRate, goBack } from '@/common'
import debounce from '@/common/debounce'
export default {
  name: 'ReceivableDetail',
  components: {
    DatePicker,
    BottomBtns,
    SelectCurrency,
    SelectUnit,
    SearchSku,
    OperationLog,
    PushBtn,
  },
  data() {
    return {
      id: undefined,
      sn: undefined,
      docType: 'ReceivableOrder',
      isAdjust: false,
      isCopy: false,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      // 表格数据
      tableData: [],
      // 每一列的插槽名 - 表格行内编辑用
      customRenderList: [
        'quantity',
        'taxRate',
        'unitPrice',
        'amountWithoutTax',
        'unitPriceWithTax',
      ],
      // 表格loading
      tableLoading: false,
      // 表格选中key
      selectedRowKeys: [],
      formData: {
        accountingDate: undefined,
        bizDate: undefined,
        warehouseCode: undefined,
        invoiceType: undefined,
        taxAmount: undefined,
        amountWithoutTax: undefined,
        receivableAmount: undefined,
        settlementMethod: undefined,
        functionalCurrency: 'CNY',
        settlementCurrency: 'CNY',
        status: 'DRAFT',
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      currentGood: {},
      purchaseAllAmount: 0,
      purchaseAllQuantity: 0,
      totalReceivableAmount: 0,
      supplierIndex: undefined,
      calculateTypeNew: 'BY_TOTAL',
      byUnitTaxPrice: true,
      cancelLoading: false,
      receiptPlanList: [],
      detailType: 'goods',
      byPercent: true,
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['receivable_order_type']),
    ...mapGetters(['sourceOrderTypeMapping', 'receivableOrderStatusMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 300 + 'px'
    },
    columns() {
      let list = [
        {
          title: '#',
          align: 'center',
          key: 'NO',
          width: 60,
          scopedSlots: {
            customRender: 'no',
          },
        },
        {
          title: this.$t('商品信息'),
          dataIndex: 'productName',
          width: 200,
          scopedSlots: {
            customRender: 'productName',
          },
        },
        {
          title: this.$t('商品规格'),
          align: 'center',
          dataIndex: 'itemStyle',
          width: 120,
          scopedSlots: {
            customRender: 'itemStyle',
          },
        },
        {
          align: 'center',
          dataIndex: 'unit',
          width: 100,
          title: this.$t('单位'),
          scopedSlots: {
            customRender: 'unit',
          },
        },
        {
          title: this.$t('数量'),
          align: 'center',
          dataIndex: 'quantity',
          width: 120,
          scopedSlots: {
            customRender: 'quantity',
          },
        },
        {
          title: this.$t('单价'),
          align: 'center',
          dataIndex: 'unitPrice',
          width: 120,
          scopedSlots: {
            customRender: 'unitPrice',
          },
        },
        {
          title: this.$t('税率') + '(%)',
          align: 'center',
          dataIndex: 'taxRate',
          width: 120,
          scopedSlots: {
            customRender: 'taxRate',
          },
        },
        {
          title: this.$t('税额'),
          align: 'center',
          dataIndex: 'taxAmount',
          width: 120,
          scopedSlots: {
            customRender: 'taxAmount',
          },
        },
        {
          title: this.$t('含税单价'),
          align: 'center',
          dataIndex: 'unitPriceWithTax',
          width: 120,
          scopedSlots: {
            customRender: 'unitPriceWithTax',
          },
        },
        {
          align: 'center',
          dataIndex: 'discountAmount',
          width: 120,
          slots: { title: 'discountAmountTitle' },
          customRender: (text) => this.showPrice(Number(text)),
        },
        {
          title: this.$t('金额'),
          align: 'center',
          dataIndex: 'amountWithoutTax',
          width: 120,
          scopedSlots: {
            customRender: 'amountWithoutTax',
          },
        },
        {
          title: this.$t('价税合计'),
          align: 'center',
          dataIndex: 'receivableAmount',
          width: 120,
          customRender: (text) => this.showPrice(Number(text)),
        },
        {
          title: this.$t('备注'),
          align: 'center',
          dataIndex: 'remark',
          width: 120,
          scopedSlots: {
            customRender: 'remark',
          },
        },
      ]
      if (this.isEdit || !this.isDetail) {
        list.push({
          title: this.$t('操作'),
          align: 'center',
          width: 120,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action',
          },
        })
      }
      return list
    },
    receiptPlanColumns() {
      let list = [
        {
          title: this.$t('是否预收'),
          dataIndex: 'isPrepay',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'isPrepay',
          },
        },
        {
          title: this.$t('到期日'),
          dataIndex: 'dueDate',
          width: 180,
          scopedSlots: {
            customRender: 'dueDate',
          },
        },
        {
          title: this.$t('应收比例') + '(%)',
          dataIndex: 'receivableRatio',
          scopedSlots: {
            customRender: 'receivableRatio',
          },
          width: 180,
        },
        {
          title: this.$t('应收金额'),
          dataIndex: 'receivableAmount',
          scopedSlots: {
            customRender: 'receivableAmount',
          },
          width: 180,
        },
      ]
      if (this.isDetail) {
        list.push(
          ...[
            {
              title: this.$t('已锁定金额'),
              dataIndex: 'lockAmount',
              customRender: (text) => this.showPrice(text),
            },
            {
              title: this.$t('未锁定金额'),
              dataIndex: 'unLockAmount',
              customRender: (text, record) =>
                this.showPrice(subtract(record.receivableAmount, record.lockAmount || 0)),
            },
            {
              title: this.$t('已核销金额'),
              dataIndex: 'writtenOffAmount',
              customRender: (text) => this.showPrice(text),
            },
            {
              title: this.$t('未核销金额'),
              dataIndex: 'unWrittenOffAmount',
              customRender: (text, record) =>
                this.showPrice(subtract(record.receivableAmount, record.writtenOffAmount || 0)),
            },
            {
              title: this.$t('冻结状态'),
              dataIndex: 'status',
              customRender: (text) => (text === 'NORMAL' ? this.$t('未冻结') : this.$t('已冻结')),
            },
          ]
        )
      }
      return list
    },
    showReceiptPlanList() {
      // return this.receiptPlanList.filter((item) => item.showStatus !== -1)
      return this.receiptPlanList
    },
  },
  methods: {
    init() {
      this.isCopy = false
      this.isEdit = false
      this.isAdjust = false
      this.isDetail = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
        this.formData.bizDate = moment().format('YYYY-MM-DD')
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
    },
    changeBU(value) {
      this.formData.businessUnitCode = value.code
      this.formData.businessUnitName = value.name
      this.formData.functionalCurrency = value.currency
    },
    showPrice(num) {
      if (num) {
        return this.$currencySymbol(num, this.formData.settlementCurrency)
      }
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    getDetailInfo() {
      let receivableOrderFormdata = localStorage.getItem('receivableOrderFormdata')
      if (this.id) {
        this.loading = true
        http({
          url: api.getReceivableDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            this.tableData = deepClone(result.items).map((item) => {
              if (item.unitList) {
                item.unitList = JSON.parse(item.unitList)
              } else {
                item.unitList = []
              }
              return item
            })
            this.receiptPlanList = deepClone(result.receiptPlanList).map((item) => {
              item.isPrepay = Boolean(+item.isPrepay)
              return item
            })
            this.formData = deepClone(result)
            this.loading = false
            if (result.status === 'DRAFT') {
              this.isEdit = true
            }
            this.setTotalAmount(1)
          },
          fail: (err) => {
            this.loading = false
            this.$message.error(err.msg)
          },
        })
      } else if (receivableOrderFormdata) {
        this.isCopy = true
        let data = JSON.parse(receivableOrderFormdata)
        this.formData = data
        if (this.$route.query.isAdjust) {
          this.isAdjust = true
          this.formData.sourceOrderType = this.docType
          this.formData.sourceOrderCode = this.formData.orderSn
          this.formData.orderType = 'ADJUSTMENT'
        }
        this.formData.orderSn = undefined
        this.formData.id = undefined
        this.formData.status = 'DRAFT'
        this.tableData = deepClone(this.formData.tableData)
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
        delete this.formData.tableData
        localStorage.removeItem('receivableOrderFormdata')
        this.setTotalAmount()

        if (this.$route.query.isAdjust) {
          this.calReceiptPlan()
        }
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    copyOrder(isAdjust) {
      let url = 'receivableDetail'
      if (isAdjust) {
        //调整单金额为负
        this.formData.tableData = this.tableData.map((item) => {
          item.amountWithoutTax = -item.amountWithoutTax
          item.taxRate = ''
          item.taxAmount = -item.taxAmount
          item.receivableAmount = -item.receivableAmount
          return item
        })
        this.formData.taxRate = ''
        this.receiptPlanList = []
        this.addReceiptPlan({ ratio: 100 })
        this.formData.receiptPlanList = this.receiptPlanList.map((item) => {
          return { ...item, receivableAmount: -item.receivableAmount }
        })
        url += '?isAdjust=1'
      } else {
        this.formData.tableData = this.tableData
        this.formData.receiptPlanList = this.receiptPlanList
      }
      let data = JSON.stringify(this.formData)
      localStorage.setItem('receivableOrderFormdata', data)
      this.$router.push(url)
    },
    backForm(back = 0) {
      goBack('receivableList', back)
    },
    addReceiptPlan({ ratio, amount, due_date, index }) {
      let receivableRatio = '',
        receivableAmount = '',
        dueDate = '',
        amountWithoutTax = ''
      if (ratio) {
        receivableRatio = ratio
        receivableAmount = this.getAmountByRatio(ratio)
        amountWithoutTax = this.getAmountByRatio(ratio, this.formData.amountWithoutTax)
      } else if (amount) {
        receivableAmount = amount
        receivableRatio = this.getRatioByAmount(amount)
        amountWithoutTax = this.getRatioByAmount(amount, this.formData.amountWithoutTax)
      }
      if (due_date) {
        dueDate = due_date
      }

      let taxRate = this.formData.taxRate,
        taxAmount = ''
      if (amountWithoutTax && receivableAmount) {
        taxAmount = Number(subtract(receivableAmount, amountWithoutTax).toFixed(4))
      }
      const data = {
        isPrepay: false,
        dueDate,
        receivableRatio,
        receivableAmount,
        taxRate,
        taxAmount,
        amountWithoutTax,
      }
      if (index !== undefined) {
        this.receiptPlanList.splice(index, 0, data)
      } else {
        this.receiptPlanList.push(data)
      }
      this.scrollToBottom()
    },
    deleteReceiptPlan() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.receiptPlanList = this.receiptPlanList.filter(
        (item, index) => !this.selectedRowKeys.includes(index)
      )
      // this.receiptPlanList
      //   .filter((item) => item.showStatus !== -1)
      //   .forEach((item, index) => {
      //     if (this.selectedRowKeys.includes(index)) {
      //       this.$set(item, 'showStatus', -1)
      //       item.receivableRatio = 0
      //       item.receivableAmount = 0
      //       item.amountWithoutTax = 0
      //       item.taxAmount = 0
      //     }
      //   })
      this.selectedRowKeys = []
    },
    chooseDueDate(record, data) {
      record.dueDate = data.data
    },
    calReceiptPlan() {
      if (this.receiptPlanList.length === 0) {
        this.addReceiptPlan({ ratio: 100 })
      } else {
        this.receiptPlanList.forEach((item) => {
          if (this.byPercent) {
            if (item.receivableRatio) {
              this.$set(item, 'receivableAmount', this.getAmountByRatio(item.receivableRatio))
            }
          } else if (item.receivableAmount) {
            this.$set(item, 'receivableRatio', this.getRatioByAmount(item.receivableAmount))
          }
          if (item.receivableRatio) {
            item.amountWithoutTax = this.getAmountByRatio(
              item.receivableRatio,
              this.formData.amountWithoutTax
            )
          }
        })
        this.calcReceiptPlanTax()
      }
    },
    checkLessPercent(record, index) {
      if (!record.receivableRatio) return
      let totalPercent = 0
      if (record.receivableRatio > 100) {
        record.receivableRatio = 100
        record.receivableAmount = this.formData.receivableAmount
      }
      record.receivableAmount = this.getAmountByRatio(record.receivableRatio)

      this.receiptPlanList.forEach((item) => {
        totalPercent = add(totalPercent, +item.receivableRatio)
      })
      if (totalPercent > 100) {
        this.$message.error(this.$t('应收比例总和不允许大于100%'))
      } else if (totalPercent < 100) {
        this.addReceiptPlan({
          ratio: subtract(100, totalPercent),
          due_date: record.dueDate,
          index: index + 1,
        })
        this.$nextTick(() => {
          this.calReceiptPlan()
        })
      }
    },
    checkLessAmount(record, index) {
      if (!record.receivableAmount) return
      let totalAmount = 0
      record.receivableRatio = this.getRatioByAmount(record.receivableAmount)

      this.receiptPlanList.forEach((item) => {
        totalAmount = add(totalAmount, +item.receivableAmount)
      })

      if (totalAmount > this.formData.receivableAmount) {
        this.$message.error(this.$t('应收金额总和不允许大于订单总金额'))
      } else if (totalAmount < this.formData.receivableAmount) {
        this.addReceiptPlan({
          amount: subtract(this.formData.receivableAmount, totalAmount),
          due_date: record.dueDate,
          index: index + 1,
        })
      }
      this.$nextTick(() => {
        this.calReceiptPlan()
      })
    },
    calcReceiptPlanTax() {
      this.receiptPlanList.forEach((item) => {
        item.taxRate = this.formData.taxRate
        if (item.amountWithoutTax && item.receivableAmount) {
          item.taxAmount = Number(subtract(item.receivableAmount, item.amountWithoutTax).toFixed(4))
        }
      })
    },
    onSubmit: debounce(async function (status) {
      if (this.tableData.length === 0) {
        this.$warning({
          title: this.$t(`警告`),
          content: this.$t(`请添加商品`),
        })
        return
      }
      const data = deepClone(this.formData)
      if (this.id) {
        data.id = this.id
      }
      data.status = status
      data.items = deepClone(this.tableData).map((item) => {
        let targetUnit = item.unitList?.find((elem) => elem.unitNameFull === item.rawUnit) || {}
        let { rate } = getUnitRate({ list: item.unitList, targetUnit })
        item.baseQuantity = Math.round(multiply(item.quantity, rate || 1))
        delete item.skuStyleList
        return item
      })
      data.receiptPlanList = deepClone(this.receiptPlanList)
      data.merchantCode = localStorage.getItem('merchantCode')
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      await http({
        url: api.createReceivableOrder,
        data: {
          order: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$message.success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    setTotalAmount(skip = 0) {
      this.calcAllAmount()
      if (!skip) {
        this.calReceiptPlan()
      }
    },
    handleChangeSku(data) {
      this.currentGood = {
        amountWithoutTax: '',
        baseQuantity: '',
        baseUnit: '',
        discountAmount: '',
        discountRate: '',
        gtin: data.barcode,
        itemName: data.skuName,
        skuStyleList: data.skuStyleList,
        receivableAmount: '',
        quantity: '',
        rawUnit: '',
        taxAmount: '',
        taxRate: 0,
        unitPrice: '',
        unitPriceWithTax: '',
        unitList: data.unitList || [],
        remark: '',
      }
    },
    handleChange(value, colName, index) {
      const newData = [...this.tableData]
      const target = newData[index]
      if (target) {
        target[colName] = value
        this.calcGoodsAmount(colName)
        this.tableData = newData
      }
    },
    calcGoodsAmount(colName) {
      this.getTheAmountInPcFn(colName)
    },
    calcAllAmount() {
      let quantity = 0,
        amount = 0,
        taxAmount = 0,
        totalAmount = 0
      this.tableData.forEach((item) => {
        quantity = add(quantity, item.quantity)
        amount = add(amount, item.amountWithoutTax)
        taxAmount = add(taxAmount, item.taxAmount)
        totalAmount = add(totalAmount, item.receivableAmount)
      })
      this.purchaseAllQuantity = quantity
      this.totalReceivableAmount = Number(totalAmount.toFixed(4))
      this.formData.amountWithoutTax = this.purchaseAllAmount = Number(amount.toFixed(4))
      this.formData.taxAmount = Number(taxAmount.toFixed(4))

      let result = this.formData.amountWithoutTax * 1 + this.formData.taxAmount * 1
      this.formData.receivableAmount = isNaN(result) ? '' : Number(result.toFixed(4))
    },
    onDelect(index) {
      this.tableData.splice(index, 1)
      this.$nextTick(() => {
        this.calcGoodsAmount()
      })
    },
    addGoodsToTable() {
      if (this.currentGood.itemName) {
        this.currentGood.itemStyle = this.currentGood.skuStyleList
          .map((item) => item.styleValue)
          .join(';')
        let procurementUnitItem =
            this.currentGood.unitList?.find((item) => item.procurementUnit) || {},
          baseUnitItem = this.currentGood.unitList?.find((item) => item.baseUnit) || {}
        this.currentGood.rawUnit = procurementUnitItem.unitNameFull || baseUnitItem.unitNameFull
        this.currentGood.baseUnit = baseUnitItem.unitNameFull
        if (
          this.tableData.length &&
          this.tableData.findIndex((i) => i.gtin == this.currentGood.gtin) !== -1
        ) {
          return this.$message.error(this.$t('该商品已存在，不可重复添加'))
        }
        this.tableData.push(this.currentGood)
        this.scrollToBottom()
      }
    },
    getTheAmountInPcFn() {
      if (!this.tableData.length) return
      let hasEmpty = false
      this.tableData.forEach((item) => {
        // console.log(item)
        if (!this.isAdjust) {
          item.taxAmount = 0
        }
        if (item.quantity && (item.unitPrice || item.unitPriceWithTax || item.amountWithoutTax)) {
          //修改单价和数量 小计跟着改
          if (this.calculateTypeNew === 'BY_TOTAL') {
            item.unitPrice = toFixedUp(divide(item.amountWithoutTax, item.quantity), 4)
            item.unitPriceWithTax = multiply(
              divide(item.amountWithoutTax, item.quantity),
              1 + item.taxRate / 100
            ).toFixed(4)
            if (!this.isAdjust) {
              item.taxAmount = this.getAmountByRatio(item.taxRate || 0, item.amountWithoutTax)
            }
            item.receivableAmount = add(item.amountWithoutTax, item.taxAmount).toFixed(4)
            let calTotalAmount = multiply(item.quantity, item.unitPrice)
            item.discountAmount = (calTotalAmount - item.amountWithoutTax).toFixed(4)
          } else {
            if (this.byUnitTaxPrice) {
              item.unitPrice = toFixedUp(item.unitPriceWithTax * (1 / (1 + item.taxRate / 100)), 4)
            } else {
              item.unitPriceWithTax = (item.unitPrice * (1 + item.taxRate / 100)).toFixed(4)
            }
            item.amountWithoutTax = multiply(item.unitPrice, item.quantity).toFixed(4)
            if (!this.isAdjust) {
              item.taxAmount = this.getAmountByRatio(item.taxRate, item.amountWithoutTax)
            }
            item.receivableAmount = multiply(item.unitPriceWithTax, item.quantity).toFixed(4)
            item.discountAmount = 0
            if (this.byUnitTaxPrice) {
              item.discountAmount = (
                add(item.amountWithoutTax, item.taxAmount).toFixed(4) - item.receivableAmount
              ).toFixed(4)
            }
          }
        } else {
          hasEmpty = true
        }
      })
      if (hasEmpty) return
      this.setTotalAmount()
    },
    getAmountByRatio(ratio, amount) {
      amount = amount || this.formData.receivableAmount
      return (multiply(amount, ratio) / 100).toFixed(4)
    },
    getRatioByAmount(rate, amount) {
      amount = amount || this.formData.receivableAmount
      return (divide(rate, amount) * 100).toFixed(4)
    },
    scrollToBottom() {
      this.$nextTick(() => {
        document.querySelector('.fixContent').scrollTo(0, 2000)
      })
    },
    getUnitPriceDisabled(col) {
      if (this.calculateTypeNew === 'BY_TOTAL') {
        return true
      } else {
        if (this.byUnitTaxPrice) {
          return col === 'unitPrice'
        } else {
          return col === 'unitPriceWithTax'
        }
      }
    },
    pushAdjust() {
      this.copyOrder(1)
    },
  },
}
</script>
